<template>
  <div>
    <!-- input search -->
    <b-row>
      <b-col>
        <b-form-group
          label="Numéro"
          label-for="Numéro"
        >
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="num"
              placeholder="Chercher"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Livreur"
          label-for="Livreur"
        >
          <v-select
            v-model="deliveryMan"
            :clearable="false"
            placeholder="Livreur"
            label="username"
            :options="deliveryMans"
            @input="getRoadmapsByDeliveryMan()"
          />
        </b-form-group>
      </b-col>
      <b-col v-if="user.role==='admin'">
        <b-form-group
          label="depot"
          label-for="depot"
        >
          <v-select
            v-model="repository"
            :clearable="false"
            placeholder="depot"
            label="name"
            :options="repositories"
            @input="getRoadmapsByRepository()"
          />
        </b-form-group>
      </b-col>
      <b-col class="mt-2">
        <b-form-group>
          <b-button
            :to="{ name: 'roadmap-add' }"
            variant="gradient-primary"
            class="ml-1 d-inline-block"
          >
            <feather-icon icon="PlusIcon" />
            <span class="ml-1">Ajouter Feuille de route</span>
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="roadmaps"
      :current-page="currentPage"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <div
          v-if="props.column.field === 'id'"
          class="text-nowrap"
        >
          <qrcode-vue
            style="display:inline"
            :value="props.row.id.toString()"
            :size="size"
            level="H"
          />
          <p class="ml-2">
            {{ props.row.id }}
          </p>
        </div>
        <!-- Column: Name -->
        <div
          v-else-if="props.column.field === 'delivery_man'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.delivery_man }}</span>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-info"
              :to="{ name: 'roadmap-show',
                     params: { id: props.row.id} }"
            >  <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />

            <router-link
              class="text-warning"
              :to="{ name: 'roadmap-edit',
                     params: { id: props.row.id} }"
            >  <feather-icon
              v-if="props.row.is_payed===false"
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> de {{ count }} entrées
            </span>
          </div>

          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="count"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="pageNext(currentPage)"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BRow, BCol,
  BSpinner, BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QrcodeVue from 'qrcode.vue'
import vSelect from 'vue-select'
import storeAuth from '@/store/store'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    QrcodeVue,
    vSelect,
    BCol,
    BRow,

  },
  data() {
    return {
      size: 80,
      isLoading: false,
      pageLength: 10,
      dir: false,
      count: 0,
      num: '',
      currentPage: 1,
      deliveryMan: {},
      deliveryMans: [],
      repositories: [],
      repository: {},
      columns: [
        {
          label: 'code',
          field: 'id',

        },
        {
          label: 'livreur',
          field: 'delivery_man',

        },
        {
          label: 'depot',
          field: 'repository',

        },
        {
          label: 'N° Colis',
          field: 'orders_count',
        },
        {
          label: 'date | heure',
          field: 'created_at',
          formatFn: this.formatFn,

        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      roadmaps: [],
      awaitingSearch: false,
      searchTerm: '',
      user: {},
    }
  },
  watch: {
    num() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.getRoadmapsFromId()
          this.awaitingSearch = false
        }, 1000) // 1 sec delay
      }
      this.awaitingSearch = true
    },
  },
  created() {
    this.user = storeAuth.state.user
    this.getRoadmaps(1)
    this.getDeliveryMans()
    if (this.user.role === 'admin') {
      this.getRepositories()
    }
  },
  methods: {
    async getRepositories() {
      await axios.get('/api/repositories/').then(response => {
        this.repositories = response.data
      })
    },
    async getDeliveryMans() {
      this.isLoading = true
      await axios.get('api/auth/users/drivers/').then(response => {
        this.deliveryMans = response.data
        this.isLoading = false
      })
    },
    async pageNext(currentPage) {
      this.getRoadmaps(currentPage)
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    async getRoadmaps(currentPage) {
      this.isLoading = true
      const { data } = await axios.get('api/roadmaps/list/', {
        params: { p: currentPage },
      })
      this.roadmaps = data.results
      this.count = data.count
      this.isLoading = false
    },
    async getRoadmapsFromId() {
      this.repository = {}
      this.deliveryMan = {}
      this.isLoading = true
      const { data } = await axios.get('api/roadmaps/list/', {
        params: { id: this.num },
      })
      this.roadmaps = data.results
      this.count = 1
      this.isLoading = false
    },
    async getRoadmapsByDeliveryMan(currentPage) {
      this.num = ''
      this.repository = ''
      this.isLoading = true
      const { data } = await axios.get('api/roadmaps/list/', {
        params: { delivery_man: this.deliveryMan.id, p: currentPage },
      })
      this.roadmaps = data.results
      this.count = data.count
      this.isLoading = false
    },
    async getRoadmapsByRepository(currentPage) {
      this.num = ''
      this.deliveryMan = ''
      this.isLoading = true
      const { data } = await axios.get('api/roadmaps/list/', {
        params: { repository: this.repository.id, p: currentPage },
      })
      this.roadmaps = data.results
      this.count = data.count
      this.isLoading = false
    },
    // eslint-disable-next-line no-dupe-keys, vue/no-dupe-keys
    async pageNext(currentPage) {
      if (this.deliveryMan !== '') {
        this.getRoadmapsByDeliveryMan(currentPage)
      } else if (this.repository !== '') {
        this.getRoadmapsByRepository(currentPage)
      } else {
        this.getRoadmaps(currentPage)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
